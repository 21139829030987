<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Cria novo Cliente"
      :enableClose="false"
    >
      <form
        class="block__form"
        @submit.prevent="salvar()"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input
                id="nome"
                v-model="item.nome"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="razaoSocial">Razão Social</label>
              <input
                id="razaoSocial"
                v-model="item.razaoSocial"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="cnpj">CNPJ</label>
              <the-mask
                id="cnpj"
                v-model="item.cnpj"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="telefone">Telefone</label>
              <the-mask
                id="telefone"
                v-model="item.telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row" style="padding-top: 20px">
          <div class="col-md-12">
            <strong>Responsável</strong>
            <span style="font-style: italic; font-size: 11px"
              >Será criado um usuário admin para este reponsável</span
            >
            <hr class="full-hr" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="nome_responsavel">Nome</label>
              <input
                id="nome_responsavel"
                v-model="item.responsavel.nome"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="telefone_responsavel">Telefone</label>
              <the-mask
                id="telefone_responsavel"
                v-model="item.responsavel.telefone"
                :mask="['(##) ####-####', '(##) #####-####']"
                class="form-control"
                required
              />
            </div>
          </div>
          
          <div class="col-md-6">
            <div class="form-group">
              <label for="email_responsavel">E-mail</label>
              <input
                id="email_responsavel"
                v-model="item.responsavel.user.email"
                type="email"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="cargo">Cargo/Função</label>
              <input
                id="cargo"
                v-model="item.responsavel.cargo"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="senha">Senha de Acesso</label>
              <input
                id="senha"
                v-model="item.responsavel.user.senha"
                type="password"
                class="form-control"
                :required="!editando"
              />
            </div>
          </div>
        </div>
        <hr class="full-hr" />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="cep">CEP</label>
              <the-mask
                id="cep"
                v-model="item.responsavel.user.endereco.cep"
                :mask="['#####-###']"
                @input="onCepInput"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="numero">Número</label>
              <input
                id="numero"
                v-model="item.responsavel.user.endereco.numero"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="complemento">Complemento</label>
              <input
                id="complemento"
                v-model="item.responsavel.user.endereco.complemento"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="rua">Rua</label>
              <input
                id="rua"
                v-model="item.responsavel.user.endereco.rua"
                type="text"
                class="form-control"
                disabled
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="bairro">Bairro</label>
              <input
                id="bairro"
                v-model="item.responsavel.user.endereco.bairro"
                type="text"
                class="form-control"
                disabled
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="cidade">Cidade</label>
              <input
                id="cidade"
                v-model="item.responsavel.user.endereco.cidade"
                type="text"
                class="form-control"
                disabled
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="estado">Estado</label>
              <input
                id="estado"
                v-model="item.responsavel.user.endereco.estado"
                type="text"
                class="form-control"
                disabled
                required
              />
            </div>
          </div>
        </div>
        <hr class="full-hr" />
        <div class="row">
          <div class="col-sm-12">
            <div class="float-right">
              <button
                id="salvarCliente"
                type="submit"
                class="btn btn-classic"
                :disabled="!formIsValid"
              >
                Salvar
              </button>
              <button
                type="button"
                id="cancelarModalCliente"
                class="btn btn-secondary m-2"
                @click="showModalCreate = false"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Clientes</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Clientes
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-7">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-1"></div>
                <div class="col-md-2 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-outline"
                        @click.prevent="trashRoute"
                        id="lixeiraAno"
                      >
                        LIXEIRA
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2 d-flex">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                        id="novoAno"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col" style="text-align: left">CNPJ</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.nome)"
                    />
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.cnpj)"
                    />
                    <td>
                      <input v-model="items[index].id" type="hidden" />
                      <input v-model="items[index].nome" type="hidden" />
                      <input v-model="items[index].razaoSocial" type="hidden" />
                      <input v-model="items[index].cnpj" type="hidden" />
                      <input v-model="items[index].telefone" type="hidden" />

                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="editar(index)"
                        id="editarAno"
                      >
                        <i class="far fa-edit" />
                      </button>
                      <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(items[index].id)"
                        id="deletarAno"
                      >
                        <i class="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import { externalRequest } from "@/tools";
import Swal from "sweetalert2";
import store from "@/store";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      showLeftMenu: true,
      showModalCreate: false,
      editando: false,
      id: null,
      index: -1,
      item: {
        id: null,
        nome: "",
        razaoSocial: "",
        cnpj: "",
        telefone: "",
        responsavel: {
          nome: "",
          telefone: "",
          cargo: "",
          user: {
            email: "",
            senha: "",
            endereco: {
              cep: '',
              rua: '',
              bairro: '',
              numero: '',
              complemento: '',
              cidade: '',
              estado: '',
            }
          },
        },
      },
      items: [],
      filter: "",
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    formIsValid() {
      if (
        !this.item.nome ||
        this.item.nome == "" ||
        !this.item.razaoSocial ||
        this.item.razaoSocial == "" ||
        !this.item.cnpj ||
        this.item.cnpj == "" ||
        !this.item.telefone ||
        this.item.telefone == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    trashRoute() {
      this.$router.push("clientes-lixeira");
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.editando = false;
      this.item = {
        id: null,
        nome: "",
        razaoSocial: "",
        cnpj: "",
        telefone: "",
        responsavel: {
          nome: "",
          telefone: "",
          cargo: "",
          user: {
            email: "",
            senha: "",
            endereco: {
              cep: '',
              rua: '',
              bairro: '',
              numero: '',
              complemento: '',
              cidade: '',
              estado: '',
            },
          },
        },
      };
      this.index = -1;
    },
    salvar() {
      this.saveServer();
    },
    buscarDadosCep() {
      if(!this.item.responsavel.user.endereco.cep || this.item.responsavel.user.endereco.cep.length != 8){
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "CEP não informado ou formato inválido. Devem ser preenchidos apenas os dígitos.",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
      const req = externalRequest(`https://viacep.com.br/ws/`);
      req.get(`${this.item.responsavel.user.endereco.cep}/json/`)
      .then(res => {
        this.preencherDadosCep(res.data)
      })
    },
    onCepInput(){
      if(this.item.responsavel.user.endereco.cep && this.item.responsavel.user.endereco.cep.replace('-','').length == 8){
        this.buscarDadosCep();
      }
    },
    preencherDadosCep(dados){
      this.item.responsavel.user.endereco.rua = dados.logradouro
      this.item.responsavel.user.endereco.bairro = dados.bairro
      this.item.responsavel.user.endereco.cidade = dados.localidade
      this.item.responsavel.user.endereco.estado = dados.uf
    },
    
    deletar(index) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção desse cliente?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$api
              .put("clientes/" + index + "/trash", {})
              .then((response) => {
                this.$root.$emit("Spinner::hide");

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Cliente deletado com sucesso!",
                  showConfirmButton: false,
                  timer: 1500,
                });

                setTimeout(function () {
                  location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Ocorreu um erro.",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    editar(index) {
      this.index = index;
      this.item = this.items[index];
      this.editando = true;
      this.showModalCreate = true;
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      this.$api
        .get("clientes")
        .then((response) => {
          console.log(response);
          response.data.forEach((res) => {
            if (res.trashAt == null) {
              if(!res.responsavel.user.endereco){
                res.responsavel.user.endereco = {
                  cep: '',
                  rua: '',
                  bairro: '',
                  numero: '',
                  complemento: '',
                  cidade: '',
                  estado: '',
                }
              }
              this.items.push(res);
            }
          });
          // this.items = response.data;
          this.$root.$emit("Spinner::hide");
          this.showModalCreate = false;
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Você não tem acesso à esta ação neste módulo",
            showConfirmButton: false,
            timer: 1500,
          });

          this.showModalCreate = false;

          this.$root.$emit("Spinner::hide");
        });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      if (this.index >= 0) {
        this.$api
          .put("cliente-edit/" + this.item.id, this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Cliente alterado com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.showModalCreate = false;
              location.reload();
            });
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");

            if (error.response.status == 403) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
                showConfirmButton: false,
                timer: 2500,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.violations[0].message +
                  "</span>",
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } else {
        this.$api
          .post("cliente-add", this.item)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Cliente cadastrado com sucesso!",
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              this.index = -1;
            });
            setTimeout(function () {
              location.reload();
            }, 1000);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            if (error.response.status == 400) {
              Swal.fire({
                position: "center",
                icon: "error",
                title:
                  '<span style="font-size: 20px;">' +
                  error.response.data.detail +
                  "</span>",
                showConfirmButton: false,
                timer: 3500,
              });
            } else if (error.response.status == 401) {
              store.dispatch("auth/ActionSignOut");
              window._Vue.$router.push({ name: "login" });
            } else {
              // Swal.fire({
              //   position: "center",
              //   icon: 'error',
              //   title: '<span style="font-size: 20px;">' + error.response.data.violations[0].message + '</span>',
              //   showConfirmButton: false,
              //   timer: 2500
              // });
              // Swal.fire({
              //   position: "center",
              //   icon: "success",
              //   title: "Cliente cadastrado com sucesso!",
              //   showConfirmButton: false,
              //   timer: 1500,
              // }).then(() => {
              //   window.location.reload();
              // });
            }
          });
      }
    },
  },
};
</script>

<style>
.vm {
  max-width: 900px;
}
</style>
